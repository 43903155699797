/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./scss-responsive/fonts.scss";
@import "/src/scss-responsive/common.scss";
@import "/src/scss-responsive/images.scss";
@import "/src/scss-responsive/icons.scss";
@import "/src/scss-responsive/overwrite-material.scss";
@import "/src/scss-responsive/print.scss";
@import "/src/scss-responsive/media-query/xl.scss";
@import "/src/scss-responsive/media-query/between-lg-xl.scss";
@import "/src/scss-responsive/media-query/lg.scss";
@import "/src/scss-responsive/media-query/md.scss";
@import "/src/scss-responsive/media-query/sm.scss";
@import "/src/scss-responsive/media-query/xs.scss";
@import "/src/scss-responsive/mat-theme-vars.scss";

ion-header {
  --padding-start: 15px;
  --padding-end: 15px;
}

ion-searchbar {
  padding-left: 0 !important;
  padding-right: 4px !important;
  .searchbar-input-container {
    input {
      font-size: 12px !important;
    }
  }
}

ion-content {
  --padding-start: 15px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-end: 15px;
}

.defaultPadding {
  padding-left: 15px;
  padding-right: 15px;
}

.defaultInput {
  --background: #f6f6f6;
  height: 40px;
  border-radius: 8px;
  --placeholder-color: #8f8f8f;
  --placeholder-opacity: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  --padding-start: 10px;
}

.defaultBtn {
  margin: 0;
  height: 40px;
  background: #067856;
  border-radius: 8px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
}

.commonHeader {
  ion-toolbar {
    --background: white;
    --min-height: 60px;
    --padding-start: 9px;
    --padding-end: 9px;
    --padding-top: 11px;
    --padding-bottom: 11px;
    max-height: 56px;
    text-align: center;
    ion-button {
      ion-icon {
        font-size: 24px !important;
      }
    }
    ion-title {
      padding: 0;
      // font-family: DM Sans;
      font-weight: 500;
      font-size: large;
      line-height: 24px;
      /* identical to box height */

      color: #067856;
    }
  }
}

.toolbar2 {
  --min-height: 70px;
  --padding-start: 15px;
  --padding-end: 15px;

  --background: #f2f2f2;

  .searchBar2 {
    --background: #ffffff;
    padding: 0 !important;
    --min-height: 40px;
    height: 40px !important;

    .searchbar-input-container.sc-ion-searchbar-ios {
      height: 40px;
    }
  }
}

.secHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.custombulletStyle {
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 10px;

  background: #067856;
  opacity: 0.5;
  margin: 0 8px;
}
.customActivebulletStyle {
  background: #067856;
  opacity: 1;
  width: 12px;
  height: 12px;
}
app-login {
  background: #f6f6f6;
}
.otpInput {
  width: 68px !important;
  height: 50px !important;
  border: 1px solid #04785540;
  // font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  line-height: 23px;
  text-align: center;
  color: #808080;
  // opacity: 0.5;
}
ion-toast.toastCustom {
  --max-width: 242px;
}
ion-toast.toastCustom::part(container) {
  min-height: 40px;
  background: #dfecca !important;
  border: 1px solid #84c318;
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
ion-toast.toastCustom::part(message) {
  // font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  text-align: center;

  color: #000000;
  text-align: left;
}
ion-toast.toastCustom::part(button) {
  padding: 0;
  width: 16px;
  margin-left: 12px;
}
ion-toast.toastCustomFailure {
  --max-width: 242px;
}
ion-toast.toastCustomFailure::part(container) {
  min-height: 40px;
  background: #f4d9ce !important;
  border: 1px solid #eb5e28;
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
ion-toast.toastCustomFailure::part(message) {
  // font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  text-align: center;

  color: #000000;
  text-align: left;
}
ion-toast.toastCustomFailure::part(button) {
  padding: 0;
  width: 16px;
  margin-left: 12px;
}
.comingsoon {
  display: flex;
  align-content: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-primary);
  font-weight: 300;
}
.addtoCartFab {
  position: absolute;
  display: inline-flex;
  background: #067856;
  border-radius: 8px;
  z-index: 9999;
  right: 0px;
  top: -12px;
  .remove {
    border-radius: 8px 0px 0px 8px;
    width: 24px;
    height: 24px;
    // font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }
  .add {
    border-radius: 0px 8px 8px 0px;
    width: 24px;
    height: 24px;
    // font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  .count {
    width: 24px;
    height: 22px;
    // font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1px 0;
    color: #067856;
    background: white;
  }
}
.addtocartBtn {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--ion-color-primary);
  color: white;
  display: flex;
  align-items: center;
  top: -8px;
  /* z-index: 99999999999999999999; */
  right: -8px;
}
.prodList {
  .modal-wrapper {
    background: white;
    width: 90vw;
    height: 50vh;
  }
}
.offlineModal {
  .modal-wrapper {
    background: white;
    width: 90vw;
    height: 50vh;
  }
}
.locationModal {
  .modal-wrapper {
    background: white;
    width: 330px;
    height: 177px;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
    border-radius: 12px;
  }
}

.optUpdateModal {
  .modal-wrapper {
    background: white;
    width: 80%;
    height: 28%;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
    border-radius: 12px;
  }
}

.reqUpdateModal {
  .modal-wrapper {
    background: white;
    width: 80%;
    height: 22%;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 15%);
    border-radius: 12px;
  }
}
.sc-ion-input-md-h {
  padding: 6px !important;
}

.action-sheet-group.sc-ion-action-sheet-md:first-child {
  border-top-left-radius: 28px !important;
  border-top-right-radius: 28px !important;
}

.delete-modal {
  --height: 45%;
  align-items: flex-end;
}

.segbtns ion-label {
  margin: 6px;
}

.cmnInputItem.item-has-value {
  --background: #e3f7ec !important;
  border-bottom: 2px solid #4dcd83 !important;
}

.cmnInputItem {
  height: 64px;
  border-radius: 5.33px 5.33px 0px 0px;
}

.modalstyle {
  --background: #ffffff00;
  .modal-wrapper {
    --background: white;
    max-height: 55%;
    min-height: 50%;
    margin: 25px;
  }
}

.couponModalStyle {
  --background: #ffffff00;
  .modal-wrapper {
    --background: white;
    max-height: 30%;
    min-height: 30%;
    margin: 25px;
    border-radius: 10px;
  }
}

.locationModal {
  --background: #ffffff00;
  --backdrop-opacity: 0.5 !important;

  .ion-page {
    --background: white;
    max-height: 80vh;
    min-height: 80vh;
    margin: 10vh 25px;
    border-radius: 10px;

    ion-content {
      --padding-start: 0px;
      --padding-top: 0px;
      --padding-bottom: 0px;
      --padding-end: 0px;
    }
  }
}

.logoAuth {
  width: 50vw;
  padding-bottom: 15px;
}
.search-input .searchbar-input {
  color: black;
  background-color: white;
}
.counterCart {
  padding: 3px;
  position: absolute;
  top: -3px;
  left: 15px;
  width: 18px;
  background: #89c320;
  font-size: 8px;
  color: white;
  height: 16px;
  font-weight: 700;
  border-radius: 50%;
  line-height: 1.3;
}
ion-backdrop {
  pointer-events: none !important;
}

.pick-address-modal {
  .modal-wrapper.sc-ion-modal-md {
    top: 50%;
    height: 50%;
    overflow: auto;
    position: fixed;
    border-radius: 15px 15px 0 0;
  }
}

.paymentOptions .modal-wrapper {
  position: relative;
  border-radius: 10px 10px 0 0;
  margin: 0;
  top: calc(50% - 100px);
  min-height: 200px;
  max-height: 200px;
}

.variantOptions .modal-wrapper {
  position: relative;
  border-radius: 10px 10px 0 0;
  margin: 0;
  top: calc(50% - 250px);
  min-height: 500px;
  max-height: 500px;
}

.menu-icon {
  padding: 10px !important;
  border-radius: 0 !important;
  color: white !important;
}
ion-menu-button {
  --color: #067856 !important;
  color: #067856 !important;
}

// BARCODE SCANNER STARTS

.scanner-ui {
  display: none;
}
.scanner-hide {
  visibility: visible;
}

body.qrscanner {
  background-color: transparent;
}
body.qrscanner .scanner-ui {
  display: block;
}
body.qrscanner .scanner-hide {
  visibility: hidden;
}

.scanner-ui-border {
  height: 25%;
  width: 100%;
  position: absolute;
  margin: 2px;
  border: 5px;
  border: #28ba62;
  border-style: solid;
  border-width: 5px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
// BARCODE SCANNER ENDS


.addtoCartFab {
  position: absolute;
  display: inline-flex;
  background: #067856;
  border-radius: 8px;
  z-index: 9999;
  right: 0px;
  top: -12px;
  .remove {
    border-radius: 8px 0px 0px 8px;
    width: 24px;
    height: 24px;
    // font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }
  .add {
    border-radius: 0px 8px 8px 0px;
    width: 24px;
    height: 24px;
    // font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  .count {
    width: 24px;
    height: 22px;
    // font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1px 0;
    color: #067856;
    background: white;
  }
}
.addtocartBtn {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #067856;
  color: white;
  display: flex;
  align-items: center;
  top: -8px;
  /* z-index: 99999999999999999999; */
  right: -8px;
}
.prodList {
  .modal-wrapper {
    background: white;
    width: 90vw;
    height: 50vh;
  }
}

.order-status {
  font-weight: bold;

  &.green {
    color: $primayColor !important;
  }

  &.orange {
    color: #8c6d21 !important;
  }

  &.red {
    color: orangered !important;
  }
}

.discount-icon {
  margin-left: 6px;
}

ion-button {
  button {
    border-radius: 10px !important;
  }
}

ion-button {
  --border-radius: 10px;
  min-height: 56px;
  font-size: 16px;
  }

.required {
  color: red;
}

