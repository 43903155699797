
body  {
    .field{
        width: 100%;

        button,
        mat-form-field{
            width: 100%;
        }

        button {
            &.big{
                height: 50px;
            }
        }
    }

    .mat-tab-links{
        justify-content: center;
    }

    mat-progress-bar.global-progress-bar {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
    }

    .mat-drawer-container-has-open{
        height: 100vh;
    }

    mat-dialog-container.mat-dialog-container{
        padding: 0px;
    }

    mat-toolbar{
        &.mat-toolbar-row,
        &.mat-toolbar-single-row{
            padding: 0 18px;
        }
    }
    .no-field-padding {

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    }

    .cdk-overlay-dark-backdrop{
        background: rgba(0, 0, 0, 0.60);
    }

    .with-suffix.mat-form-field-appearance-outline .mat-form-field-suffix {
        top: 0;
        right: -5px;
    }

    .ion-toolbar {
        height: 56px;
    }

}
