$muteColor: #F1F1F1;
$linkColor: #0814ff;
$highlightColor: #f1f1f1;
$activeLinkColor: #007bff;
$commonTextColor: #232323;
$dullColor: #B0B0B0;
$defaultFontColor: #323232;
$defaultDullColor: #666666;
$borderColor: #d8d8dd;
$borderColorOpaqe: rgba(216, 216, 221, 0.733);
$borderRightColor2: #979797;
$borderRightColor: rgba(151, 151, 151, 0.4);
$dullBgColor: #e3e9eb;
$pinBgColor: #DDE0E1;
$mainBg: #e5eaf5;
$highlightColor: hsla(350, 100%, 46%, 0.208);
$primayColor: #067856;

$failedOpaqueColor: rgba(255, 0, 0, 0.276);
$unpublishedColor: rgba(255, 0, 0, 0.276);
$inactiveColor: $dullBgColor;

$plain: #FFF;
$darkColor: black;
$warningColor: red;

/**
value variables
*/

$leftMargin: 20;
$appDefaultFontThin: 'Inter',
sans-serif;
$appDefaultFontBold: 'Inter',
sans-serif;
$logoFont: 'Guraldo';
$statusFont: 'goldman-regular';


$spacingValue: 9px;
$spacingValue1: 18px;
$spacingValue2: $spacingValue1 * 2;
$spacingValue3: $spacingValue1 * 3;
