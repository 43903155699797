.ico{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.ico-male-profile{
    @extend .ico;
    background-image: url('/src/assets/img/icons/ico-male-user.png');
    width: 27px;
    height: 27px;  
}