$mainContainerWidth: calc(100% - 110px);

.sm-score-column,
.score-column {
    display: none;
}

@media (min-width: 1600px) {
}

@media (max-width: 1601px) {
    
}

// Large devices (desktops, 992px and up)
@media (min-width: 1200px) and (max-width: 1599px) {

     
}