@use "sass:map";

@import 'themes/default.scss';

$primaryColor: map.get($mat-primary, 800);
$primaryColor500: map.get($mat-primary, 500);
$accentColor: map.get($mat-accent, A200);
$warnColor: map.get($mat-accent, 800);

$primaryColorOpaque: #d9eadb;
$accentColorOpaque: #ffe1cc;
$warnColorOpaque: #fdd5d6;