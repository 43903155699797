@import 'variables';
@import 'mat-theme-vars';

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-space-between {
  justify-content: space-between;
}

.v-center {
  align-items: center;
}

.vh-center {
  align-items: center;
  justify-content: center;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.h-right {
  justify-content: flex-end;
}

.h-center {
  justify-content: center;
}

.v-start {
  align-items: flex-start;
}

.space-evenly {
  justify-content: space-evenly;
}

.v-stretch {
  align-items: stretch;
}

.flex-spacer,
.flex-auto {
  flex: 1 1 auto;
}

.width-100 {
  width: 100%;
}

.vh-100 {
  min-height: 100vh;
}

.mat-progress-spinner {
  &.inner-spinner {
    position: absolute;
    right: 2px;
    top: 17px;
  }

  &.main-spinner {
    position: relative;
  }
}

.height-100vh {
  min-height: 100vh;
}

.height-100 {
  min-height: 100%;
}

.active {
  color: $activeLinkColor;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 900px;
}


.link {
  color: $primaryColor;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 9px;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
}

.m-1 {
  margin: $spacingValue1;
}

.mb-1 {
  margin-bottom: $spacingValue1;
}

.mb-2 {
  margin-bottom: $spacingValue1 * 2;
}

.mt-2 {
  margin-top: $spacingValue2;
}

.mt-3 {
  margin-top: $spacingValue3;
}

.ml-1 {
  margin-left: $spacingValue1;
}

.m-auto {
  margin: 0 auto;
}

.p-1 {
  padding: $spacingValue1;
}

.pr-1 {
  padding-right: $spacingValue1;
}

.pl-1 {
  padding-left: $spacingValue1;
}

.pt-1 {
  padding-top: $spacingValue1;
}

.pb-1 {
  padding-bottom: $spacingValue1;
}

.bg-primary500 {
  background-color: $primaryColor500;
}

.p-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.t-left {
  text-align: left;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.dull-color {
  color: $defaultDullColor;
}

.rounded {
  border-radius: 9px;
}

.bg-primary {
  background-color: $primaryColor;
}

.bg-accent {
  background-color: $accentColor;
}

.bg-plain {
  background-color: $plain;
}

.primary-color {
  color: $primaryColor;
}

.accent-color {
  color: $accentColor;
}

.warn-color {
  color: $warnColor;
}

.dull-color {
  color: $defaultFontColor;
}

.pointer {
  cursor: pointer;
}

.section-title-wrapper {
  border-bottom: 1px solid $dullBgColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: $spacingValue1;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  padding: $spacingValue1;
}

.status-text {
  font-family: $statusFont;
  font-size: 30px;
}

.status-circle {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dullBgColor;

  &.success {
    background-color: $primaryColorOpaque;

    .status-text {
      color: $primaryColor;
    }
  }

  &.pending {
    background-color: $warnColorOpaque;

    .status-text {
      color: red;
    }
  }
}


.status-label {
  text-align: right;

  &.Accepted {
    color: $primaryColor;
  }

  &.Delivered {
    color: $primaryColor;
  }

  &.InStore {
    color: red;
  }

  &.Outbound {
    color: $accentColor;
  }

  &.Packed {
    color: red;
  }

  &.Rejected {
    color: $warnColor;
  }
}

.section-wrapper {
  background-color: $plain;
  margin-bottom: $spacingValue2;
}

.initial-load {
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
}

.modal-title {
  color: #FFF;
  height: 45px;
  align-items: center;

  .title {
    font-size: 18px;
  }

  padding: 0 $spacingValue1;
}

.modal-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  background: white;
}

.modal-content {
  padding: $spacingValue1;
  overflow: auto;
  // height: calc(100% - 158px);
}

.modal-footer {
  padding: $spacingValue1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  box-sizing: border-box;
}

.full-screen-modal .mat-dialog-container {
  max-width: none;
}

.viewport::ng-deep video {
  min-width: 90%;
  object-fit: cover;
}

.mat-autocomplete-panel {
  background: white !important;
  color: rgba(0, 0, 0, 0.87);
}

td {
  border-color: $dullBgColor;
}

.opaque-hidden {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: -100000px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px;
  text-align: center;
}

.q-btn {
  background: transparent;
}

.width-45 {
  width: 45% !important;
  display: inline-flex;
  margin: 0 6px;
}
