@import '/src/scss-responsive/variables.scss';

@font-face {
    font-family: $logoFont;
    src: url('/src/assets/fonts/guraldo/guaraldo.ttf');
}

@font-face {
    font-family: $statusFont;
    src: url('/src/assets/fonts/goldman/Goldman-Regular.ttf');
}
